import React, { useState } from "react";
import { Navigate, Outlet, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { Button, Grid, Menu, Space, theme } from "antd";

import { MenuOutlined } from "@ant-design/icons";

import { Content } from "antd/es/layout/layout";

const { useToken } = theme;
const { useBreakpoint } = Grid;

export const ProtectedLayout = () => {
  const { access_token, logout } = useAuth();
  const { token } = useToken();
  const [current, setCurrent] = useState("projects");
  const screens = useBreakpoint();
  const navigate = useNavigate();

  if (!access_token) {
    return <Navigate to="/login" />;
  }

  // if (!user_token) {
  //      // user is not authenticated
  //      console.log("user is not authenticated");
  //      return <Navigate to="/login" />;
  // }

  const menuItems = [
    {
      label: "Dashboard",
      key: "app",
    },
    // {
    //   label: "Vehicules",
    //   key: "app/vehicles",
    // },
    //     {
    //       label: "Products",
    //       key: "SubMenu",
    //       children: [
    //         {
    //           label: "Ant Design System",
    //           key: "product:1",
    //         },
    //         {
    //           label: "Ant Design Charts",
    //           key: "product:2",
    //         },
    //       ],
    //     }
  ];

  const onLogout = () => {
    logout();
  };

  const onClick = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
    navigate("/" + e.key, { replace: true });
  };

  const styles = {
    container: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 auto",
      maxWidth: token.screenXL,
      padding: screens.md ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`
    },
    header: {
      backgroundColor: token.colorBgContainer,
      borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
      position: "relative"
    },
    logo: {
      display: "block",
      height: token.sizeLG,
      left: "50%",
      position: screens.md ? "static" : "absolute",
      top: "50%",
      transform: screens.md ? " " : "translate(-50%, -50%)"
    },
    menu: {
      backgroundColor: "transparent",
      borderBottom: "none",
      lineHeight: screens.sm ? "4rem" : "3.5rem",
      marginLeft: screens.md ? "0px" : `-${token.size}px`,
      width: screens.md ? "inherit" : token.sizeXXL
    },
    menuContainer: {
      alignItems: "center",
      display: "flex",
      gap: token.size,
      width: "100%"
    }
  };

  return (
    <div>
      <header>
        {/* @ts-expect-error */}
        <nav style={styles.header}>
          <div style={styles.container}>
            <div style={styles.menuContainer}>
              {/* <a style={styles.logo} href="#">
              <Logo showText={true} />
            </a> */}
              <Menu
                style={styles.menu}
                mode="horizontal"
                items={menuItems}
                onClick={onClick}
                /* @ts-expect-error */
                selectedKeys={screens.md ? [current] : ""}
                overflowedIndicator={
                  <Button type="text" icon={<MenuOutlined />}></Button>
                }
              />
            </div>
            <Space>
              <Button type="text" onClick={onLogout}>Logout</Button>
              {/* <Button type="primary">Sign up</Button> */}
            </Space>
          </div>
        </nav>
      </header>
      <Content>
        <Outlet />
      </Content>
    </div>
  )
};

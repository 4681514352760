import React, { useState, useEffect } from 'react';

import { usePostHog } from 'posthog-js/react'

import { useAuth } from "../hooks/useAuth";
import { User, UserApi } from "../api/api";
import { Configuration } from "../api/configuration";

export default function Profile() {
    const { access_token } = useAuth();
    const posthog = usePostHog()

    const [user, setUser] = useState<User>();

    useEffect(() => {
        console.log("load profile");
        var api_config = new Configuration()
        api_config.accessToken = access_token.token
        api_config.basePath = process.env.REACT_APP_API_URL
        var userApi = new UserApi(api_config)
        userApi.getProfile().then((response) => {
            setUser(response.data);

            posthog?.identify(response.data.id, {
                email: "",
                is_subscribed: response.data.stripe_portal_link ? true : false
            })

        }).catch((error) => {
            console.log(error)
        });
    }, []);

    return (
        <div>
            <h1>Profile</h1>
            {!user?.stripe_portal_link &&
                <div>
                    <a href={user?.stripe_subscribe_link}>Subscribe</a>
                </div>
            }
            {user?.stripe_portal_link &&
                <div>
                    <a href={user?.stripe_portal_link}>Manage</a>
                </div>
            }
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import { Input, Result, Card, Row, Col, Switch, Button, DatePicker, Modal, Steps, message } from 'antd';
import { CarTwoTone, PlusOutlined, DeleteOutlined, GlobalOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useAuth } from "../hooks/useAuth";
import { Planning, Rate, Vehicle as VehicleDTO, VehiclesApi } from "../api/api";
import { Configuration } from "../api/configuration";
import DaysSelector from "./DaysSelector";

const { RangePicker } = DatePicker;

interface VehicleProps {
    reload: any,
    vehicle: VehicleDTO
}

dayjs.extend(customParseFormat);

export default function Vehicle(prop: VehicleProps) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const { t, i18n } = useTranslation();
    const { access_token } = useAuth();
    const testFlagEnabled = useFeatureFlagEnabled('test')

    const [addPlanningIsOpen, setAddPlanningIsOpen] = useState(false);
    const [addPlanningCurrent, setAddPlanningCurrent] = useState(0);
    const [addPlanningNextIsLoading, setAddPlanningNextIsLoading] = useState(false);
    const [addPlanningLocationId, setAddPlanningLocationId] = useState("");
    const [addPlanningLocationIdError, setAddPlanningLocationIdError] = useState(false);
    const [addPlanningLocationIdRates, setAddPlanningLocationIdRates] = useState<Rate[]>([]);
    const [addPlanningLocationIdValidRate, setAddPlanningLocationIdValidRate] = useState<Rate>();
    const [addPlanningDays, setAddPlanningDays] = useState<boolean[]>([true, true, true, true, true, true, false]);

    // const [newPlanning, setNewPlanning] = useState<Planning>({});
    const [newPause, setNewPause] = useState<any>(null);


    // i18n.changeLanguage("fr");

    const getVehicleAPIClient = () => {
        var api_config = new Configuration()
        api_config.accessToken = access_token.token
        api_config.basePath = process.env.REACT_APP_API_URL
        var vehiclesApi = new VehiclesApi(api_config)
        return vehiclesApi;
    }


    const steps = [
        {
            title: 'Select the location',
        },
        {
            title: 'Confirm the rate',
        },
        {
            title: 'Select the days',
        },
    ];

    const resetAddPlanningFlow = () => {
        setAddPlanningLocationId("");
        setAddPlanningCurrent(0);
        setAddPlanningIsOpen(false);
        setAddPlanningNextIsLoading(false);
        setAddPlanningLocationIdValidRate(undefined);
        setAddPlanningLocationIdError(false);
        setAddPlanningDays([true, true, true, true, true, true, false]);
    }

    const handleCancel = () => {
        resetAddPlanningFlow();
    };

    const NextAddPlanning = () => {
        if (addPlanningCurrent < steps.length - 1) {
            if (addPlanningCurrent === 0) {
                setAddPlanningNextIsLoading(true);
                var vehiclesApi = getVehicleAPIClient()
                vehiclesApi.getRateForLocation(prop.vehicle.licensePlate, addPlanningLocationId).then((response) => {
                    var validLocationId = false;
                    response.data.forEach((r) => {
                        if (r.isValid) {
                            validLocationId = true;
                            setAddPlanningNextIsLoading(false);
                            setAddPlanningLocationIdValidRate(r);
                            setAddPlanningCurrent(addPlanningCurrent + 1);
                        }
                    });
                    if (validLocationId == false) {
                        setAddPlanningNextIsLoading(true);
                        setAddPlanningLocationIdValidRate(undefined);
                        setAddPlanningCurrent(addPlanningCurrent + 1);
                    }
                }, (error) => {
                    message.error('Invalid location');
                    setAddPlanningNextIsLoading(false);
                    setAddPlanningLocationIdError(true);
                });
            } else {
                setAddPlanningCurrent(addPlanningCurrent + 1);
            }
        } else {
            setAddPlanningNextIsLoading(true);
            var vehiclesApi = getVehicleAPIClient()
            var p: Planning = {
                locationId: addPlanningLocationId,
                on_monday: addPlanningDays[0],
                on_tuesday: addPlanningDays[1],
                on_wednesday: addPlanningDays[2],
                on_thursday: addPlanningDays[3],
                on_friday: addPlanningDays[4],
                on_saturday: addPlanningDays[5],
                on_sunday: addPlanningDays[6],
            }

            vehiclesApi.addPlanning(prop.vehicle.licensePlate, p).then((response) => {
                resetAddPlanningFlow();
                prop.reload();
            }, (error) => {
                console.log(error);
            });
        }
    }

    const addPlanningStepItems = steps.map((item) => ({ key: item.title, title: item.title }));

    const displayPlate = (plate: string) => {
        var newFrenchPlate = /^([A-Z]{2})(\d{3})([A-Z]{2})$/;
        var result = newFrenchPlate.exec(plate);
        if (result?.length === 4) {
            return result[1] + "-" + result[2] + "-" + result[3];
        }

        return plate;
    }

    const onChange = (planning: Planning, day: string, checked: boolean) => {
        switch (day) {
            case "monday":
                planning.on_monday = checked;
                break;
            case "tuesday":
                planning.on_tuesday = checked;
                break;
            case "wednesday":
                planning.on_wednesday = checked;
                break;
            case "thursday":
                planning.on_thursday = checked;
                break;
            case "friday":
                planning.on_friday = checked;
                break;
            case "saturday":
                planning.on_saturday = checked;
                break;
            case "sunday":
                planning.on_sunday = checked;
                break;
        }

        var vehiclesApi = getVehicleAPIClient()
        vehiclesApi.updatePlanning(prop.vehicle.licensePlate, planning.id!, planning).then((response) => {
            prop.reload();
        }, (error) => {
            console.log(error);
        });
    }

    const addPause = (planningId: string, nPause: any, cb: any) => {
        if (nPause === null) {
            return;
        }
        var vehiclesApi = getVehicleAPIClient()
        vehiclesApi.addPause(prop.vehicle.licensePlate, planningId, nPause[0].format("YYYY-MM-DD"), nPause[1].format("YYYY-MM-DD")).then((response) => {
            cb();
        }, (error) => {
            console.log(error);
        });
    }

    const deletePause = (planningId: string, pauseId: string) => {
        var vehiclesApi = getVehicleAPIClient()
        vehiclesApi.deletePause(prop.vehicle.licensePlate, planningId, pauseId).then((response) => {
            prop.reload();
        }, (error) => {
            console.log(error);
        });
    }

    const pauseOnChange = (planningId: string, v: any) => {
        setNewPause([v[0], v[1]]);
        addPause(planningId, [v[0], v[1]], () => {
            prop.reload();
            setNewPause(null);
        });
    }

    const deletePlanning = (id: string) => {
        var vehiclesApi = getVehicleAPIClient()
        vehiclesApi.deletePlanning(prop.vehicle.licensePlate, id).then((response) => {
            prop.reload();
        }, (error) => {
            console.log(error);
        });
    }

    return (
        <Card title={<div><CarTwoTone /> {displayPlate(prop.vehicle.licensePlate)}</div>} extra={<Button type="dashed" onClick={() => { setAddPlanningIsOpen(true) }}>Add Planning</Button>}>
            <Row key={prop.vehicle.licensePlate + "_planning"}>
                {prop.vehicle.plannings?.map((planning: Planning) => (
                    <Col span={24} key={planning.id}>
                        <Card type="inner" title={"Location: " + planning.locationId} extra={<Button type='dashed' icon={<DeleteOutlined />} onClick={() => { deletePlanning(planning.id!) }}>
                            Delete
                        </Button>}>
                            <Row>
                                <Col xl={12} xs={24}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>{t('abbr.monday')}</td>
                                                <td>{t('abbr.tuesday')}</td>
                                                <td>{t('abbr.wednesday')}</td>
                                                <td>{t('abbr.thursday')}</td>
                                                <td>{t('abbr.friday')}</td>
                                                <td>{t('abbr.saturday')}</td>
                                                <td>{t('abbr.sunday')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_monday} onChange={() => {
                                                        onChange(planning, "monday", !planning.on_monday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_tuesday} onChange={() => {
                                                        onChange(planning, "tuesday", !planning.on_tuesday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_wednesday} onChange={() => {
                                                        onChange(planning, "wednesday", !planning.on_wednesday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_thursday} onChange={() => {
                                                        onChange(planning, "thursday", !planning.on_thursday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_friday} onChange={() => {
                                                        onChange(planning, "friday", !planning.on_friday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_saturday} onChange={() => {
                                                        onChange(planning, "saturday", !planning.on_saturday)
                                                    }} />
                                                </td>
                                                <td>
                                                    <Switch size={isMobile ? "small" : "default"} checked={planning.on_sunday} onChange={() => {
                                                        onChange(planning, "sunday", !planning.on_sunday)
                                                    }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xl={12} xs={24}>
                                    <br />
                                    <h4>Pauses:</h4>
                                    {planning.pauses?.map((pause) => (
                                        <div key={pause.id}>
                                            <p>{pause.startAt + " -> " + pause.endAt} <Button type='dashed' icon={<DeleteOutlined />} onClick={() => { deletePause(planning.id!, pause.id!) }} /></p>
                                        </div>
                                    ))}
                                    <RangePicker size={isMobile ? "small" : "middle"} onChange={(v) => { pauseOnChange(planning.id!, v) }} value={newPause} allowClear={false} minDate={dayjs(dayjs().add(1, "day"), "YYYY-MM-DD")} />
                                </Col>
                            </Row>

                        </Card>
                        <br />
                    </Col>
                ))}
            </Row>
        
            <Modal
                centered
                width={650}
                title={"Add a new planning for " + displayPlate(prop.vehicle.licensePlate)}
                open={addPlanningIsOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={NextAddPlanning} disabled={addPlanningNextIsLoading}>
                        {addPlanningCurrent < steps.length - 1 ? "Next" : "Done"}
                    </Button>,
                ]}
            >
                <>
                    <Steps size={isMobile ? "small" : "default"} current={addPlanningCurrent} items={addPlanningStepItems} />
                    <br />
                    <br />
                    {addPlanningCurrent === 0 && (
                        <div>
                            <Input placeholder="Location" status={addPlanningLocationIdError ? "error" : ""} prefix={<GlobalOutlined />} value={addPlanningLocationId} onChange={e => setAddPlanningLocationId(e.target.value)} />
                        </div>
                    )}
                    {addPlanningCurrent === 1 && (
                        <div>
                            {addPlanningLocationIdValidRate == null && (
                                <Result
                                    status="warning"
                                    title="No residential parking rate found"
                                    subTitle="Sadly, we don't have a valid rate for this location as we only support residential parking for now" />
                            )}
                            {addPlanningLocationIdValidRate != undefined && (
                                <Result
                                    status="success"
                                    title="Looks good!"
                                    subTitle="You have a valid rate for this location" />
                            )}
                        </div>
                    )}
                    {addPlanningCurrent === 2 && (
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>{t('abbr.monday')}</td>
                                        <td>{t('abbr.tuesday')}</td>
                                        <td>{t('abbr.wednesday')}</td>
                                        <td>{t('abbr.thursday')}</td>
                                        <td>{t('abbr.friday')}</td>
                                        <td>{t('abbr.saturday')}</td>
                                        <td>{t('abbr.sunday')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[0]} onChange={() => { setAddPlanningDays([!addPlanningDays[0], addPlanningDays[1], addPlanningDays[2], addPlanningDays[3], addPlanningDays[4], addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[1]} onChange={() => { setAddPlanningDays([addPlanningDays[0], !addPlanningDays[1], addPlanningDays[2], addPlanningDays[3], addPlanningDays[4], addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[2]} onChange={() => { setAddPlanningDays([addPlanningDays[0], addPlanningDays[1], !addPlanningDays[2], addPlanningDays[3], addPlanningDays[4], addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[3]} onChange={() => { setAddPlanningDays([addPlanningDays[0], addPlanningDays[1], addPlanningDays[2], !addPlanningDays[3], addPlanningDays[4], addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[4]} onChange={() => { setAddPlanningDays([addPlanningDays[0], addPlanningDays[1], addPlanningDays[2], addPlanningDays[3], !addPlanningDays[4], addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[5]} onChange={() => { setAddPlanningDays([addPlanningDays[0], addPlanningDays[1], addPlanningDays[2], addPlanningDays[3], addPlanningDays[4], !addPlanningDays[5], addPlanningDays[6]]) }} />
                                        </td>
                                        <td>
                                            <Switch size={isMobile ? "small" : "default"} checked={addPlanningDays[6]} onChange={() => { setAddPlanningDays([addPlanningDays[0], addPlanningDays[1], addPlanningDays[2], addPlanningDays[3], addPlanningDays[4], addPlanningDays[5], !addPlanningDays[6]]) }} />
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            </Modal>
        </Card>
    );
}
import { Layout, theme } from 'antd';

import Vehicles from "../Components/Vehicles";
import Sessions from "../Components/Sessions";
import Profile from "../Components/Profile";

const { Header, Content, Footer, Sider } = Layout;


export default function DashboardPage() {

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout>
            {/* <Sider breakpoint='lg' collapsedWidth='0'>
            </Sider> */}
            <Layout>
                {/* <Header style={{ padding: 0, background: colorBgContainer }}>
                    
                </Header> */}
                <Content style={{ margin: '24px 16px 0' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <div>
                            <Profile />
                            <Vehicles />
                            {/* <Sessions /> */}
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Arkham Consulting ©{new Date().getFullYear()} Created with ❤️
                </Footer>
            </Layout>
        </Layout>

    );
}

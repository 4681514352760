import './App.css';
import { Routes, Route } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import { ProtectedLayout } from './Layouts/ProtectedLayout';
import DashboardPage from './Pages/Dashboard';
// import VehiclesPage from './Pages/Vehicles';
import { HomeLayout } from './Layouts/HomeLayout';
import Terms from './Components/Layout/Terms';


function App() {

  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>
      <Route path="/terms" element={<Terms />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/app" element={<ProtectedLayout />}>
        <Route path="" element={<DashboardPage />} />
        {/* <Route path="vehicles" element={<VehiclesPage />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
